<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton btnTitle="Add New" :showBtn="false" :showAddNew="true" title="Stock Consumption List"
                   @onClickAddNewButton="onClickAddNewButton"/>
      <div class="row match-height mt-1">
        <div class="col-12 col-lg-8 col-xl-9 col-xxl-7">
          <DateQuerySetter @onClickGo="getStockConsumptions">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <label
                for="colFormLabel"
                class="col-form-label"
              >
                Consumption Number
              </label>
              <input
                v-model="consumptionNumber"
                type="text"
                class="form-control date-mask"
                placeholder="Consumption no"
              >
            </div>
          </DateQuerySetter>
        </div>
      </div>
    </div>

    <ListTable :consumption-list="consumptionList"/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
    <GlobalLoader />
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable from '@/components/molecule/procurement/inventory/stock/consumption/ListingTable'
import Pagination from '@/components/atom/Pagination'
import {useStore} from "vuex";
import {inject, onMounted, ref} from "vue";
import handleStock from "@/services/modules/stock";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";

const router = useRouter();
const route = useRoute();
const store = useStore();
const offset = ref(20);
const showError = inject('showError');
const {fetchStockConsumptionList} = handleStock();
const consumptionList = ref([]);
const consumptionNumber = ref("");

function onClickAddNewButton() {
  router.push({name: `stock-consumption-add`, params: route.params, query: route.query});
}

const getQuery = () => {
  let queryObject = Object.assign({}, {
    start_date: route.query.start,
    end_date: route.query.end,
    company_id: route.params.companyId,
    offset: offset.value,
  });

  if (consumptionNumber.value) {
    queryObject.consumption_number = consumptionNumber.value;
  }

  if (route.query.page) {
    queryObject.page = route.query.page;
  }

  const queryString = new URLSearchParams(queryObject).toString();
  return `?${queryString}`;
}

function getStockConsumptions() {
  fetchStockConsumptionList(getQuery()).then(res => {
    if(res.data) {
      consumptionList.value = res.data.data;
      setPagination(res.data)
    }else{
      consumptionList.value = [];
    }
  }).catch( err => {
    showError(err.response.message);
  })
}

const onPageChange = async (page) => {
  let routeQuery  = Object.assign({}, $route.query)
  routeQuery.page = page
  await router.push({path: route.path, query: routeQuery})
  getStockConsumptions();
}

function setPagination(data) {
  if(!data.data.length) return
  store.commit('setPaginationData', {
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
}

onMounted(() => {
  getStockConsumptions();
})

</script>
